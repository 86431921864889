import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle, ChevronLeft, ChevronRight } from 'lucide-react';
import axios from 'axios';

const LINE_STATES = [
  { value: 'draft', label: 'Borrador' },
  { value: 'registered', label: 'Registrado' },
  { value: 'paid', label: 'Pagado' },
  { value: 'sent', label: 'Enviado' },
  { value: 'done', label: 'Completado' },
  { value: 'cancel', label: 'Cancelado' }
];

export default function SalesOrderLinesManagement() {
  const [orderLines, setOrderLines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 10
  });

  useEffect(() => {
    setIsComponentMounted(true);
    return () => {
      setIsComponentMounted(false);
    };
  }, []);

  const fetchOrderLines = async (page = 1) => {
    if (!isComponentMounted) return;

    try {
      setError(null);
      setLoading(true);
      const apiUrl = process.env.REACT_APP_API_URL;
      
      if (!apiUrl) {
        throw new Error('API URL no está configurada');
      }

      const response = await axios.get(`${apiUrl}/sales-order-lines/?page=${page}`);
      
      if (isComponentMounted) {
        setOrderLines(response.data.results || []);
        setPagination({
          currentPage: page,
          totalPages: Math.ceil(response.data.count / 10),
          totalItems: response.data.count,
          itemsPerPage: 10
        });
      }
    } catch (err) {
      if (isComponentMounted && err.response?.status !== 401) {
        console.error('Error fetching order lines:', err);
        setError(err.response?.data?.detail || err.message);
      }
    } finally {
      if (isComponentMounted) {
        setLoading(false);
      }
    }
  };

  const updateOrderLineState = async (lineId, newState) => {
    if (!isComponentMounted) return;

    try {
      setError(null);
      const apiUrl = process.env.REACT_APP_API_URL;
      
      if (!apiUrl) {
        throw new Error('API URL no está configurada');
      }

      const response = await axios.patch(`${apiUrl}/sales-order-lines/`, {
        id_salesorderline: lineId,
        state: newState
      });

      if (isComponentMounted) {
        const updatedLine = response.data;
        setOrderLines(prevLines => 
          prevLines.map(line => 
            line.id_salesorderline === lineId ? updatedLine : line
          )
        );
        setSuccessMessage(`Estado actualizado correctamente a ${LINE_STATES.find(s => s.value === newState)?.label}`);
        setTimeout(() => {
          if (isComponentMounted) {
            setSuccessMessage('');
          }
        }, 3000);
      }
    } catch (err) {
      if (isComponentMounted && err.response?.status !== 401) {
        console.error('Error updating order line:', err);
        setError(err.response?.data?.detail || err.message);
        setTimeout(() => {
          if (isComponentMounted) {
            setError(null);
          }
        }, 3000);
      }
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      fetchOrderLines(newPage);
    }
  };

  useEffect(() => {
    if (isComponentMounted) {
      fetchOrderLines(1);
    }
  }, [isComponentMounted]);

  if (loading) return (
    <div className="flex items-center justify-center h-48">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
  );

  return (
    <div className="container mx-auto p-4" style={{ backgroundColor: 'var(--bg-color)', color: 'var(--text-color)' }}>
      <h1 className="text-2xl font-bold mb-4" style={{ color: 'var(--primary-color)' }}>Gestión de Líneas de Pedido</h1>

      {error && (
        <div className="bg-red-50 border p-4 rounded-md mb-4" style={{ backgroundColor: '#2a1a1a', color: '#f87171', borderColor: '#742a2a' }}>
          <AlertCircle className="inline-block h-4 w-4 mr-2" />
          <span>{error}</span>
        </div>
      )}

      {successMessage && (
        <div className="bg-green-50 border p-4 rounded-md mb-4" style={{ backgroundColor: '#1a2a1a', color: '#4ade80', borderColor: '#2a742a' }}>
          <CheckCircle className="inline-block h-4 w-4 mr-2" />
          <span>{successMessage}</span>
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full border" style={{ backgroundColor: 'var(--card-bg-color)', borderColor: 'var(--border-color)', borderRadius: 'var(--border-radius)' }}>
          <thead style={{ backgroundColor: 'var(--bg-color)', color: 'var(--text-secondary)' }}>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">ID Línea</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Pedido</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Producto</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Cantidad</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Precio Unitario</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Subtotal</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Estado</th>
            </tr>
          </thead>
          <tbody className="divide-y" style={{ divideColor: 'var(--border-color)' }}>
            {orderLines.map((line) => (
              <tr key={line.id_salesorderline}>
                <td className="px-6 py-4 whitespace-nowrap">{line.id_salesorderline}</td>
                <td className="px-6 py-4 whitespace-nowrap">{line.id_salesorder_id}</td>
                <td className="px-6 py-4 whitespace-nowrap">{line.product_name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{line.product_qty}</td>
                <td className="px-6 py-4 whitespace-nowrap">S/{line.price_unit}</td>
                <td className="px-6 py-4 whitespace-nowrap">S/{line.price_subtotal}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <select
                    value={line.state}
                    onChange={(e) => updateOrderLineState(line.id_salesorderline, e.target.value)}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border focus:outline-none sm:text-sm rounded-md"
                    style={{ backgroundColor: 'var(--card-bg-color)', color: 'var(--text-color)', borderColor: 'var(--border-color)' }}
                  >
                    {LINE_STATES.map(({ value, label }) => (
                      <option key={value} value={value} style={{ backgroundColor: 'var(--bg-color)', color: 'var(--text-color)' }}>
                        {label}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Paginación */}
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center">
            <span className="text-sm">
              Mostrando {((pagination.currentPage - 1) * pagination.itemsPerPage) + 1} a {Math.min(pagination.currentPage * pagination.itemsPerPage, pagination.totalItems)} de {pagination.totalItems} registros
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(pagination.currentPage - 1)}
              disabled={pagination.currentPage === 1}
              className="p-2 rounded-md border disabled:opacity-50"
              style={{ borderColor: 'var(--border-color)' }}
            >
              <ChevronLeft className="h-5 w-5" />
            </button>
            <span className="px-4 py-2">
              Página {pagination.currentPage} de {pagination.totalPages}
            </span>
            <button
              onClick={() => handlePageChange(pagination.currentPage + 1)}
              disabled={pagination.currentPage === pagination.totalPages}
              className="p-2 rounded-md border disabled:opacity-50"
              style={{ borderColor: 'var(--border-color)' }}
            >
              <ChevronRight className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}